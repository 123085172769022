<template>
  <footer class="u-align-center u-clearfix u-footer u-grey-80 u-footer" id="sec-51a9">
    <div class="u-clearfix u-sheet u-valign-top-xl u-sheet-1">
      <div class="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
        <div class="u-gutter-0 u-layout">
          <div class="u-layout-row">
            <div
              class="u-container-style u-layout-cell u-size-20-lg u-size-20-md u-size-20-sm u-size-20-xs u-size-30-xl u-layout-cell-1">
              <div
                class="u-container-layout u-valign-top-lg u-valign-top-md u-valign-top-sm u-valign-top-xl u-container-layout-1">
                <img
                  class="u-align-center-xs u-align-left-lg u-align-left-md u-align-left-sm u-align-left-xl u-image u-image-contain u-image-default u-preserve-proportions u-image-1"
                  src="../assets/wolfmc.png" alt="" data-image-width="213" data-image-height="254">
              </div>
            </div>
            <div
              class="u-container-style u-layout-cell u-size-14-xl u-size-18-md u-size-18-sm u-size-18-xs u-size-20-lg u-layout-cell-2">
              <div class="u-container-layout u-container-layout-2">
                <h4 class="u-align-center u-text u-text-default u-text-1">Redes Sociais</h4>
                <div class="u-align-center u-social-icons u-spacing-10 u-social-icons-1">
                  <a class="u-social-url" title="Facebook" target="_blank"
                    href="https://facebook.com/wolfmotoclube"><span
                      class="u-icon u-social-facebook u-social-icon u-text-black u-icon-1"><svg class="u-svg-link"
                        preserveAspectRatio="xMidYMin slice" viewBox="0 0 112 112" style="">
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-71ba"></use>
                      </svg><svg class="u-svg-content" viewBox="0 0 112 112" x="0" y="0" id="svg-71ba">
                        <circle fill="currentColor" cx="56.1" cy="56.1" r="55"></circle>
                        <path fill="#FFFFFF" d="M73.5,31.6h-9.1c-1.4,0-3.6,0.8-3.6,3.9v8.5h12.6L72,58.3H60.8v40.8H43.9V58.3h-8V43.9h8v-9.2
c0-6.7,3.1-17,17-17h12.5v13.9H73.5z"></path>
                      </svg></span>
                  </a>
                  <a class="u-social-url" title="Instagram" target="_blank"
                    href="https://instagram.com/wolfmotoclube"><span
                      class="u-icon u-social-icon u-social-instagram u-text-black u-icon-2"><svg class="u-svg-link"
                        preserveAspectRatio="xMidYMin slice" viewBox="0 0 112 112" style="">
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-8d08"></use>
                      </svg><svg class="u-svg-content" viewBox="0 0 112 112" x="0" y="0" id="svg-8d08">
                        <circle fill="currentColor" cx="56.1" cy="56.1" r="55"></circle>
                        <path fill="#FFFFFF"
                          d="M55.9,38.2c-9.9,0-17.9,8-17.9,17.9C38,66,46,74,55.9,74c9.9,0,17.9-8,17.9-17.9C73.8,46.2,65.8,38.2,55.9,38.2
z M55.9,66.4c-5.7,0-10.3-4.6-10.3-10.3c-0.1-5.7,4.6-10.3,10.3-10.3c5.7,0,10.3,4.6,10.3,10.3C66.2,61.8,61.6,66.4,55.9,66.4z"></path>
                        <path fill="#FFFFFF"
                          d="M74.3,33.5c-2.3,0-4.2,1.9-4.2,4.2s1.9,4.2,4.2,4.2s4.2-1.9,4.2-4.2S76.6,33.5,74.3,33.5z">
                        </path>
                        <path fill="#FFFFFF" d="M73.1,21.3H38.6c-9.7,0-17.5,7.9-17.5,17.5v34.5c0,9.7,7.9,17.6,17.5,17.6h34.5c9.7,0,17.5-7.9,17.5-17.5V38.8
C90.6,29.1,82.7,21.3,73.1,21.3z M83,73.3c0,5.5-4.5,9.9-9.9,9.9H38.6c-5.5,0-9.9-4.5-9.9-9.9V38.8c0-5.5,4.5-9.9,9.9-9.9h34.5
c5.5,0,9.9,4.5,9.9,9.9V73.3z"></path>
                      </svg></span>
                  </a>
                </div>
              </div>
            </div>
            <div
              class="u-container-style u-layout-cell u-size-16-xl u-size-20-lg u-size-22-md u-size-22-sm u-size-22-xs u-layout-cell-3">
              <div class="u-container-layout u-container-layout-3">
                <h4 class="u-align-center u-text u-text-2">Wolf Motorcycle Club</h4>
                <p class="u-align-center u-text u-text-3">
                  <a class="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-body-alt-color u-btn-1"
                    href="https://maps.google.com/?cid=17908625570205510810" target="_blank"> Rua Walter da Silva Maia,
                    442<br>Ipitanga - Lauro de Freitas-BA<br>CEP: 42706-740
                  </a>
                </p>
                <h4 class="u-align-center u-text u-text-4">Próximo Evento</h4>
                <p class="u-align-center u-text u-text-5">
                  Open Covil<br>
                  Aniversário do President<br>
                  15/03/2025 - A partir das 13H
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="u-align-center u-text u-text-6">Wolf Motorcycle Club, a sigla WFFW e os Brasões são marcas
        registradas.<br>©&nbsp;2014 - 2024
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'RodapePrincipal'
}
</script>
